import { useSignal } from "@preact/signals-react"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import { referenceArea } from "../../appState/referenceAreaSignals"
import StandortControls from "./StandortControls"


const StandortCard: React.FC = () => {
    // create a local signal to handle open
    const open = useSignal(false)

    // only show the card if there is a field selected
    if (referenceArea.value.features.length === 0) return null

    return <>
        <Accordion
            expanded={open.value}
            onChange={() => open.value = !open.peek()}
            disableGutters
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                Meine Agrarfläche
            </AccordionSummary>

            <AccordionDetails>
                <StandortControls referrer="Card" />
            </AccordionDetails>
        </Accordion>
    
    </>
}

export default StandortCard