import { Box } from "@mui/material"

interface StandortRangeProps {
    limits?: [number, number],
    range: [number, number],
    value?: number,
    bgcolor?: string
    height?: number,
    suffix?: string
}

const StandortRange: React.FC<StandortRangeProps> = ({ limits, range, value, bgcolor, height, suffix }) => {
    return <>
    <Box boxSizing="border-box">
        {/* upper labels */}
        <Box width="100%" height="1.3rem" position="relative">
            <Box position="absolute" left={((range[0] - limits![0]) / (limits![1] || 1 - limits![0] || 0) * 100 + '%')}>
                {range[0]}&nbsp;{suffix}
            </Box>
            <Box position={"absolute"} right={((limits![1] - range[1]) / (limits![1] || 1 - limits![0] || 0) * 100 + '%')}>
                {range[1]}&nbsp;{suffix}
            </Box>
        </Box>

        {/* box */}
        <Box width="100%" height={`${height || 25}px`} component="div" position="relative" sx={{boxSizing: 'border-box', background: bgcolor || '#BDD7EE'}}>
            <Box
                position="absolute" 
                height="100%" 
                sx={{border: '2px solid black', backgroundColor: 'transparent', boxSizing: 'border-box'}} 
                width={(range[1] - range[0]) / (limits![1] || 1 - limits![0] || 0) * 100 + '%'}
                left={(range[0] - limits![0]) / (limits![1] || 1 - limits![0] || 0) * 100 + '%'}
            />
            {value ? <>
                <Box
                    position="absolute"
                    height="100%"
                    sx={{backgroundColor: 'red', boxSizing: 'border-box'}}
                    width="5px"
                    left={(value! - limits![0]) / (limits![1] || 1 - limits![0] || 0) * 100 + '%'} 
                />
            </> : null}
            
        </Box>

        {/* lower labels */}
        <Box width="100%" height="1.3rem" position="relative">
            <Box position="absolute" left={0}>
                {limits![0]}&nbsp;{suffix}
            </Box>
            <Box position="absolute" right={0}>
                {limits![1]}&nbsp;{suffix}
            </Box>
        </Box>
    </Box>
    </>
}

export default StandortRange