import { Box, Paper, Tab, Tabs, Typography } from "@mui/material"
import AppHeader from "../layout/desktop/AppHeader"
import { Link } from "react-router-dom"
import { Map } from "@mui/icons-material"
import Footer from "../layout/Footer"

const DesktopDataProtection: React.FC = () => {
    return <>
        <Box display="flex" flexDirection="column" width="100vw" height="100vh">
            <AppHeader>
                <Tabs>
                    <Tab component={Link} to="/app" label="Karte" value="map" icon={<Map />} iconPosition="start" />
                </Tabs>
            </AppHeader>

            <Box width="100vw"  m="0" p="0" display="flex">
                <Paper elevation={3}  sx={{m: 'auto', p: 3, height: 'calc(100vh - 110px)', overflowY: 'scroll', width: '100%', maxWidth: '800px'}}>

                    <Typography variant="h4">Datenschutzerklärung</Typography>
                    <Typography variant="body2"> Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</Typography> 
                    <Typography variant="body2"> Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</Typography> 
                    <Typography variant="body2"> Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</Typography> 
                    <Typography variant="body2"> Unsere Datenschutzerklärung ist wie folgt gegliedert:</Typography> 
                    <Typography variant="body2"> I. Informationen über uns als Verantwortliche<br /> II. Rechte der Nutzer und Betroffenen<br /> III. Informationen zur Datenverarbeitung</Typography> 
                    <Typography variant="h5">I. Informationen über uns als Verantwortliche</Typography>
                    <Typography variant="body2"> Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</Typography> 
                    hydrocode GmbH<br /> <br /> Mombertstr. 2<br /> 76131 Karlsruhe
                    <Typography variant="body2"> 
                    Vertretungsberechtigte Personen: Mirko Mälicke (Geschäftsführer), Lucas Reid (Geschäftsführer) <br />
                        <br /> E-Mail: info@hydrocode.de
                    </Typography>

                    <Typography variant="h5">II. Rechte der Nutzer und Betroffenen</Typography>
                    <Typography variant="body2"> Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</Typography> 
                    <ul>
                    <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
                    <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
                    <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
                    <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
                    <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
                    </ul>
                    <Typography variant="body2"> Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</Typography> 
                    <Typography variant="body2"> <strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong></Typography> 
                    
                    <Typography variant="h5">III. Informationen zur Datenverarbeitung</Typography>
                    <Typography variant="body2"> Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</Typography> 
                    <Typography variant="h6">Serverdaten</Typography><Typography variant="body2"> Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Wir nutzen firebase Hosting, einen Google Dienst (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.) Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</Typography> 
                    <Typography variant="body2"> Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</Typography> 
                    <Typography variant="body2"> Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</Typography> 
                    <Typography variant="body2"> Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</Typography> 
                    
                    
                    {/* <Typography variant="h6">Webanalyse Hotjar</Typography><Typography variant="body2"> In unserem Internetauftritt setzen wir Hotjar ein. Hierbei handelt es sich um einen Webanalysedienst der Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta, Europe, nachfolgend nur „Hotjar“ genannt.</Typography> 
                    <Typography variant="body2"> Hotjar dient uns zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Im Falle einer von Ihnen erteilten Einwilligung für diese Verarbeitung ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage kann auch Art. 6 Abs. 1 lit. f DSGVO sein. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</Typography> 
                    <Typography variant="body2"> Durch Hotjar können wir Ihre Nutzungsverhalten auf unserem Internetauftritt, wie bspw. Ihre Mausbewegungen oder Mausklicks, protokollieren und auswerten. Ihre Besuch unseres Internetauftritts wird dabei aber anonymisiert. Zusätzlich werden durch Hotjar Informationen über Ihr Betriebssystem, Ihren Internet-Browser, eingehende oder ausgehende Verweise („Links“), die geographische Herkunft sowie Art und Auslösung des von Ihnen eingesetzten Endgeräts ausgewertet und zu statistischen Zwecken aufbereitet. Ebenfalls kann Hotjar ein direktes Feedback von Ihnen einholen. Zudem bietet Hotjar unter</Typography> 
                    <Typography variant="body2"> <a href="https://www.hotjar.com/privacy" target="_blank" rel="noreferrer nofollow">https://www.hotjar.com/privacy</a></Typography> 
                    <Typography variant="body2"> weitergehende Datenschutzinformationen an.</Typography> 
                    <Typography variant="body2"> Darüber hinaus haben Sie die Möglichkeit, die Analyse Ihres Nutzungsverhaltens im Wege des sog. Opt-outs zu beenden. Mit dem Bestätigen des Links</Typography> 
                    <Typography variant="body2"> <a href="https://www.hotjar.com/opt-out" target="_blank" rel="noreferrer nofollow">https://www.hotjar.com/opt-out</a></Typography> 
                    <Typography variant="body2"> wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.</Typography>  */}
                    

                    <Typography variant="h6">Mapbox API</Typography><Typography variant="body2"> Für die Karte sowie Geolocation setzen wir Mapbox API, einen Dienst der Mapbox Inc., 740 15th Street NW, 5 th Floor, Washington, District of Columbia 20005, USA, nachfolgend nur „Mapbox“ genannt, ein.</Typography> 
                    <Typography variant="body2"> Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse liegt in der Art unseres Internetauftritts, da wir diese Seite ohne Karte nicht anbieten können.</Typography> 
                    <Typography variant="body2"> Bei Aufruf einer unserer Internetseiten, in die der Dienst Mapbox eingebunden ist, wird durch Mapbox ein Cookie über Ihren Internet – Browser auf Ihrem Endgerät gespeichert. Hierdurch werden Informationen über Ihre Nutzung unseres Internetauftritts, einschließlich Ihrer IP- Adresse, an einen Server von Mapbox in den USA übertragen und dort gespeichert. Diese Daten werden zum Zwecke der Anzeige der Seite bzw. zur Gewährleistung der Funktionalität des Dienstes Mapbox verarbeitet. Diese Informationen wird Mapbox womöglich an Dritte weitergeben, sofern dies gesetzlich vorgeschrieben ist oder die Informationen durch Dritte im Auftrag von Mapbox&nbsp;verarbeitet werden.</Typography> 
                    <Typography variant="body2"> Die „Terms of Service“, die durch Mapbox unter <a href="https://www.mapbox.com/tos/#maps" rel="nofollow noreferrer" target="_blank">https://www.mapbox.com/tos/#maps</a> bereitgestellt werden, enthalten weitergehenden Informationen über die Nutzung von Mapbox und über die von Mapbox erlangten Daten.</Typography> 
                    <Typography variant="body2"> Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet -Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“. Die Nutzung des Dienstes Mapbox über unseren Internetauftritt ist dann allerdings nicht mehr möglich.</Typography> 
                    <Typography variant="body2"> Zudem bietet Mapbox unter</Typography> 
                    <Typography variant="body2"> <a href="https://www.mapbox.com/privacy/" target="_blank" rel="noreferrer nofollow">https://www.mapbox.com/privacy/</a><u> </u></Typography> 
                    <Typography variant="body2"> weitere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre an.</Typography> 

                    <Typography variant="h6">Supabase</Typography>
                    <Typography variant="body2">Unsere Webseite verwendet den Datenhosting Dienst „Supabase“ der Supabase, Inc., 970 Toa Payoh North #07-04, Singapore 318992.</Typography>
                    <Typography variant="body2">Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Supabase: <a href="https://supabase.com/docs/company/privacy" target="_blank" rel="nofollow noreferrer">https://supabase.com/docs/company/privacy</a></Typography>
                    
                    <hr />
                    <Typography variant="body2">Stand der Datenschutzerklärung: 15.07.2024</Typography>
                    <Typography variant="body2"> <a href="https://www.generator-datenschutzerklärung.de" target="_blank" rel="noreferrer">Muster-Datenschutzerklärung</a> der <a href="https://www.bewertung-löschen24.de" rel="nofollow noreferrer" target="_blank">Anwaltskanzlei Weiß &amp; Partner</a></Typography> 
                </Paper>
            </Box>

            <Footer />
        </Box>
    </>
}

export default DesktopDataProtection