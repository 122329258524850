import {  Box, Typography  } from "@mui/material"
import posthog from "posthog-js"
import { useEffect } from "react"

const MobileMain: React.FC = () => {
    useEffect(() => {
        posthog.capture('MobileMockupViewed')
    }, [])
    
    return <>
        <Box height="100vh" width="100vw" p={2} sx={{background: 'url(/icons/background.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}}>
            <Box 
                height="100%" 
                width="100%" 
                sx={{background: 'rgba(255,255,255,0.5)', boxSizing: 'border-box', borderRadius: '25px'}}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                p={3}
            >
                <Box>
                    <Typography variant="h4" textAlign="center">Willkommen beim Agroforst-Planungstool!</Typography>
                    <Typography variant="h5" textAlign="center">- Demo-Version -</Typography>
                </Box>
                
                <Box>
                    <Typography variant="body1">
                        Vielen Dank für Dein Interesse an unserer Anwendung!
                        Leider ist diese Anwendung nur auf Desktop-Geräten verfügbar.
                        Bitte besuche uns mit einem Laptop oder PC, um die volle 
                        Funktionalität zu nutzen. Wir freuen uns auf Dich!
                    </Typography>
                </Box>
                
                <Box>
                    <img src="/icons/BMEL_transp.png" alt="BMEL logo" style={{maxWidth: '250px'}} />
                </Box>

            </Box>
        </Box>
    </>
}

export default MobileMain