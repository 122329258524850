import { Box, Button, IconButton, Popover } from "@mui/material"
import { useSignal } from "@preact/signals-react"
import StandortControls from "./StandortControls"
import { useRef } from "react"
import { Close } from "@mui/icons-material"

// get the props of the Material UI Button component
type ButtonProps = Omit<React.ComponentProps<typeof Button>, 'onClick'>

const StandortPopupButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, variant, color, ...btnPros }) => {
    // create a state to track the open state of the popup
    const open = useSignal(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    return <>
    <Button
        ref={buttonRef}
        onClick={() => open.value = !open.peek()}
        variant={variant || 'text'} 
        color={color || 'success'} 
        {...btnPros}
    >
        { children }
    </Button>
    <Popover
        anchorEl={buttonRef.current}
        open={open.value}
        onClose={() => open.value = false}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        <Box p={3}>
            <IconButton 
                onClick={() => open.value = false}
                sx={{position: 'absolute', top: 0, right: 0}}
            >
                <Close />
            </IconButton>
            <StandortControls referrer="Button" />
        </Box>
    </Popover>
    </>
}

export default StandortPopupButton