import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Footer: React.FC<{noLandingPage?: boolean}> = ({ noLandingPage }) => {
  return <>
    <Box sx={{ height: '32px', boxSizing: 'border-box', boxShadow: 2, display: "flex", p: 0.5, justifyContent: "center", bgcolor: "background.paper" }}>
      { !noLandingPage ? (<>
          <Link to="/" style={{ margin: 0, padding: 0, paddingRight: 48, fontSize: 16, color: 'black' }}>Startseite</Link>
      </>) : null }
          <Link to="/impressum" style={{ margin: 0, padding: 0, paddingRight: 48, fontSize: 16, color: 'black' }}>Impressum</Link>
          <Link to="/datenschutz" style={{ margin: 0, padding: 0, fontSize: 16, color: 'black' }}>Datenschutz</Link>
    </Box>
  </>
}

export default Footer;
