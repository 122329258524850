import { useState } from "react"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useSignalEffect } from "@preact/signals-react"
import { treeLineAreaFeatures } from "../../appState/treeLineSignals"
import { treeLocationFeatures } from "../../appState/geoJsonSignals"
import { Paper, TableContainer } from "@mui/material"
import { germanSpecies } from "../../appState/backendSignals"

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Baumreihe', minWidth: 150 },
    { field: 'species', headerName: 'Arten', minWidth: 250, flex: 1, valueFormatter: (val: string[]) => val.join(', ')},
    {field: 'count', headerName: 'Anzahl Arten', minWidth: 150},
    {field: 'individuals', headerName: 'Anzahl Individuen', minWidth: 150, valueFormatter: (val: number[]) => val.join(', ')},
    {field: 'distance', headerName: 'Durchschnittlicher Abstand', minWidth: 150, valueFormatter: (val: number) => `${val.toFixed(1)} m`},
    {field: 'length', headerName: 'Länge', minWidth: 50, valueFormatter: (val: number) => `${val.toFixed(0)} m`},
]

interface Row {
    name: string,
    species: string[],
    count: number,
    individuals: number[],
    distance: number,
    length: number
}

const TreeLineTable: React.FC = () => {
    const [rows, setRows] = useState<Row[]>([])

    useSignalEffect(() => {
        const nRows = treeLineAreaFeatures.value.map((line) => {

            // filter out the trees that belong to this treeLine
            const trees = treeLocationFeatures.peek().filter(t => t.properties.treeLineId === line.properties.id)
            
            // create a set of all species
            const species = new Set(trees.map(t => t.properties.treeType))
            
            // count the number of species
            const individuals  = [...species].map(s => trees.filter(t => t.properties.treeType === s).length)

            return {
                name: line.properties.name,
                // add set as string array
                species: [...species].map(latin => germanSpecies.peek()[latin]),
                count: species.size,
                individuals: individuals,
                distance: line.properties.lineLength! / line.properties.treeCount!,
                length: line.properties.lineLength
            } as Row
        })

        setRows(nRows)
    })

    return <>
        <TableContainer sx={{ my: 2, bgcolor: "grey.100", width: '100%' }} component={Paper}>
            <DataGrid
                rows={rows.map((r, i) => ({...r, id: i}))}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
                />
        </TableContainer>
    </>
}

export default TreeLineTable