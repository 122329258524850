import { Box, Tooltip, Typography } from "@mui/material"
import { referenceAreaHectar } from "../../appState/referenceAreaSignals"
import { agriculturalArea } from "../../appState/treeLineSignals"
import { area } from "@turf/turf"
import { Info } from "@mui/icons-material"
import ShadeResultPlot from "./ShadeResult/ShadeResultPlot"

const ShadeResultCard: React.FC = () => {    
    return <>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box width="100%" p={1} display="block">
                <Typography variant="h6">Beschattung der landwirtschaftlichen Fläche</Typography>
            </Box>
            <Box width="100%" p={1} mt={2} display="block">
                <Typography variant="body1">{`Gesamtfläche:     ${referenceAreaHectar.value.toFixed(1)} ha`}</Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="body1" mr={1}>
                    {`Acker-/Weidefläche:    ${(area(agriculturalArea.value) / 10000).toFixed(1)} ha`}
                </Typography>
                <Tooltip title="Die landwirtschaftliche Fläche umfasst die für landwirtschaftliche Zwecke nutzbare Fläche, abzüglich der von Baumstreifen beanspruchten Fläche.">
                        <Info fontSize="small" color="info" />
                    </Tooltip>
                </Box>

            </Box>
            <Box p={1} mt={1} mx="auto" display="block">
                <ShadeResultPlot />
            </Box>
        </Box>
    </>
}

export default ShadeResultCard