import { Box, MenuItem, Select, Typography } from "@mui/material"
import { biomassMode, biomassProperty, biomassSimulation } from "../../appState/biomassSimulationSignals"
import { simulationStep } from "../../appState/simulationSignals"
import { referenceAreaHectar } from "../../appState/referenceAreaSignals"
import { simulationTimeSteps } from "../../appState/backendSignals"
import BiomassResultPlot from "./BiomassResult/BiomassResultPlot"



const BiomassResultCard: React.FC = () => {
    return <>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="row" mt={1} sx={{width: '100%'}}>
                <Select 
                    sx={{width: '100%'}}
                    value={biomassProperty.value} 
                    onChange={(newProp) => biomassProperty.value = newProp.target.value as 'carbon' | 'agb'}
                >
                    <MenuItem value="agb">Oberirdische Biomasse</MenuItem>
                    <MenuItem value="carbon">Oberirdischer Kohlenstoff</MenuItem>
                </Select>
                <Select 
                    sx={{width: '100%'}}
                    value={biomassMode.value} 
                    onChange={(newMode) => biomassMode.value = newMode.target.value as 'total' | 'perHectar'}
                >
                    <MenuItem value="total">Gesamt (t)</MenuItem>
                    <MenuItem value="perHectar">Pro Hektar (t / ha)</MenuItem>
                </Select>
            </Box>
            <Box p={3} display="flex" justifyContent="center" alignItems="baseline" width="100%">
                <Typography variant="h3" sx={{color: biomassProperty.value === 'agb' ? '#462e17' : '#241e19'}}>
                    { (biomassSimulation.value.total[simulationTimeSteps.value.filter(t => t <= simulationStep.value.current).length] / 1000 / (biomassMode.value === 'total' ? 1 : referenceAreaHectar.value)).toFixed(1) }
                </Typography>
                <Typography variant="h5" ml={0.3} sx={{color: biomassProperty.value === 'agb' ? '#462e17' : '#241e19'}}>
                    { biomassMode.value === 'total' ? 't' : 't / ha' }
                </Typography>
            </Box>

            <BiomassResultPlot />
        </Box>
    </>
}

export default BiomassResultCard