import { Box, Typography } from "@mui/material"
import { TreeProfile } from "../../appState/backendSignals"
import { GiBee } from "react-icons/gi"
import { PiFlower } from "react-icons/pi"
import Plot from "react-plotly.js"
import range from "lodash.range"


const BeeProfileCard: React.FC<{profile: TreeProfile}> = ({ profile }) => {
    return <>
        <Box mt={3}>
            <Typography variant="h6" component="div">Blühzeitraum</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" px={1}>
            
            <PiFlower size={64} color="#c32f69" style={{marginRight: '15px'}} />
            <Plot 
                layout={{
                    width: 250,
                    height: 100,
                    paper_bgcolor: 'transparent',
                    plot_bgcolor: 'transparent',
                    margin: {t: 10, r: 10, l: 50, b: 25},
                    xaxis: {tickvals: [3, 5, 7, 9], ticktext: ['Mär', 'Mai', 'Jul', 'Sep']},
                    yaxis: {range: [0, 2], visible: false, showgrid: false, showline: false, showticklabels: false, zeroline: false, ticks: undefined},
                }}
                data={[
                    {
                        type: 'heatmap',
                        x: [3,4,5,6,7,8,9,10],
                        y: Array(8).fill(1),
                        z: range(3, 11).map(i => i >= profile.flowering_min && i <= profile.flowering_max ? 1 : 0),
                        colorscale: [[0, 'transparent'], [1, '#c32f69']],
                        showscale: false,
                        hoverinfo: 'skip'
                    }
                ]}
                config={{displayModeBar: false}}
            />
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
            <Typography variant="h6">Anzahl unterstützter Bienenarten</Typography>
            <Box mt={3} display="flex" alignItems="center">
                <GiBee size={32} color="orange" />
                <Typography variant="h6" component="span" ml={2}>{profile.insects}</Typography>
            </Box>
        </Box>
    </>
}

export default BeeProfileCard