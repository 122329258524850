import { createBrowserRouter, RouterProvider } from "react-router-dom"

import DesktopMain from "../pages/DesktopMain"
import DesktopLanding from "../pages/DesktopLanding"
import DesktopImprint from "../pages/DesktopImprint"
import DesktopDataProtection from "../pages/DesktopDataProtection"

// create the routes used on the Desktop version of the app
const router = createBrowserRouter([
    {
        path: '/',
        element: <DesktopLanding />,
    },
    {
        path: '/app',
        element: <DesktopMain />,
    },
    {
        path: '/impressum',
        element: <DesktopImprint />
    },
    {
        path: '/datenschutz',
        element: <DesktopDataProtection />
    }
])

// the navigation is only a wrapper around the RouterProvider
const DesktopNavigation: React.FC = () => {
    return <>
        <RouterProvider router={router} />
    </>
}

export default DesktopNavigation