import { Info } from "@mui/icons-material"
import { Box, MenuItem, Select, Slider, Tooltip, Typography } from "@mui/material"
import { agriculturalUse, changeStaticData, forestryUse, protectionStatus, staticData, SummaryData } from "../../appState/summarySignals"
import { useSignal, useSignalEffect } from "@preact/signals-react"
import { posthog } from "../../tracking"

interface StandortControlsProps {
    referrer: 'Card' | 'Button'
}

const StandortControls: React.FC<StandortControlsProps> = ({ referrer }) => {
    const standortDirty = useSignal(false)
    const climateDirty = useSignal(false)

    const handleStandortChange = (key: keyof SummaryData, value: any) => {
        // implement the logic
        changeStaticData(key, value)

        // set the dirty state
        if (!standortDirty.peek()) standortDirty.value = true
    }

    const handleClimateChange = (key: keyof SummaryData, value: any) => {
        // implement the logic
        changeStaticData(key, value)

        // set the dirty state
        if (!climateDirty.peek()) climateDirty.value = true
    }

    useSignalEffect(() => {
        if (standortDirty.value) posthog.capture('StandortDialogUsed', { referrer, section: 'Flächeneigenschaften' })
    })

    useSignalEffect(() => {
        if (climateDirty.value) posthog.capture('StandortDialogUsed', { referrer, section: 'Standorteigenschaften' })
    })


    return <>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="h6">Standordeigentschaften</Typography>
            <Tooltip title="Hier kannst du die Standorteigenschaften deiner Planungsfläche eingeben. Diese kann dir bei der Baumartenauswahl behilflich sein.">
                <Info color="success" sx={{cursor: 'pointer'}} />
            </Tooltip>
        </Box>

        <Box p={1}>
            <Typography variant="body1">Jährlicher Niederschlag</Typography>
            <Slider 
                color="success"
                min={100}
                max={2000}
                step={10}
                value={staticData.value.precipitationSum}
                valueLabelDisplay="auto"
                onChange={(_, v) => handleClimateChange('precipitationSum', v as number)}
            />
            
            <Typography variant="body1">Jährliche Mitteltemperatur</Typography>
            <Slider 
                color="success"
                min={-10.0}
                max={30.0}
                step={0.1}
                value={staticData.value.averageTemperature}
                valueLabelDisplay="auto"
                onChange={(_, v) => handleClimateChange('averageTemperature', v as number)}
            />

            <Typography variant="body1">Boden-pH-Wert</Typography>
            <Slider 
                color="success"
                min={2}
                max={14}
                step={1}
                value={staticData.value.soilpH}
                valueLabelDisplay="auto"
                onChange={(_, v) => handleClimateChange('soilpH', v as number)}
            />

            <Typography variant="body1">Bodenfeuchte</Typography>
            <Slider 
                color="success"
                min={0}
                max={10}
                step={1}
                value={staticData.value.soilMoisture}
                valueLabelDisplay="auto"
                onChange={(_, v) => handleClimateChange('soilMoisture', v as number)}
            />
        </Box>

        <Typography p={1} variant="h6">Flächeneigenschaften</Typography>
        <Box p={1} mt={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">Flächennutzung</Typography>
            <Select
                variant="standard"
                size="small"
                value={staticData.value.agriculturalUse}
                onChange={e => handleStandortChange('agriculturalUse', e.target.value)}
            >
                {agriculturalUse.map(use => (
                    <MenuItem key={use} value={use}>{use}</MenuItem>
                ))}
            </Select>
        </Box>
        <Box p={1} mt={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <Typography variant="body1" mr={1}>geplante Nutzung</Typography>
                <Tooltip title="Aufgrund der Baumartenauswahl ist derzeit die Anlage von Kurzumtriebsflächen NICHT, die Anlage von Streuobstflächen nur begrenzt möglich. Diese Angabe hat keine Auswirkungen auf den Verlauf deiner Planung.">
                    <Info color="success" sx={{cursor: 'pointer'}} />
                </Tooltip>
            </Box>
            <Select
                variant="standard"
                size="small"
                multiple
                value={staticData.value.forestryUse}
                onChange={e => handleStandortChange('forestryUse', e.target.value)}
            >
                {forestryUse.map(use => (
                    <MenuItem key={use} value={use}>{use}</MenuItem>
                ))}
            </Select>
        </Box>
        <Box p={1} mt={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <Typography variant="body1" mr={1}>Schutzstatus</Typography>
                <Tooltip title="Bitte informiere dich über die Regelungen in deiner Region. Diese können je nach Schutzgebietsstatus abweichen.">
                    <Info color="success" sx={{cursor: 'pointer'}} />
                </Tooltip>
            </Box>
            <Select
                variant="standard"
                size="small"
                multiple
                value={staticData.value.protectionStatus}
                onChange={e => handleStandortChange('protectionStatus', e.target.value)}
            >
                {protectionStatus.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                ))}
            </Select>
        </Box>
    </>
}

export default StandortControls