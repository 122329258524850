import { Box, Paper, Tab, Tabs, Typography } from "@mui/material"
import AppHeader from "../layout/desktop/AppHeader"
import { Link } from "react-router-dom"
import { Map } from "@mui/icons-material"
import Footer from "../layout/Footer"

const DesktopImprint: React.FC = () => {
    return <>
        <Box display="flex" flexDirection="column" width="100vw" height="100vh">
            <AppHeader>
                <Tabs>
                    <Tab component={Link} to="/app" label="Karte" value="map" icon={<Map />} iconPosition="start" />
                </Tabs>
            </AppHeader>

            <Box width="100vw" height="calc(100vh - 104px)" m="0" p="0" display="flex" flexDirection="column">
                <Paper elevation={2} sx={{m: "auto", mt: 1, p: 2,  maxWidth: 800}}>
                    <Typography variant="h4">Impressum</Typography>
                    <Typography variant="h6" mt={2}>Diensteanbieter</Typography>
                    <Typography variant="body2" mt={0}>
                        hydrocode GmbH<br/>
                        Mombertstr. 2<br/>
                        76131 Karlsruhe
                    </Typography>

                    <Typography variant="h6" mt={2}>Kontaktmöglichkeiten</Typography>
                    <Typography variant="body2" mt={0}>
                        E-Mail-Adresse: <a href="mailto:info@hydrocode.de">info[at]hydrocode[dot]de</a>
                    </Typography>

                    <Typography variant="h6" mt={2}>Vertretungsberechtigte Personen</Typography>
                    <Typography variant="body2">
                        Mirko Mälicke (Geschäftsführer), Lucas Reid (Geschäftsführer)
                    </Typography>

                    <Typography variant="h6" mt={2}>Inhaltlich Verantwortlich</Typography>
                    <Typography variant="body2">
                        Professur für Waldwachtum und Dendroökologie<br/>
                        Albert-Ludwigs-Universität Freiburg
                    </Typography>

                    <Typography variant="h6" mt={2}>Bildrechte</Typography>
                    <Typography variant="body2">
                        Die Rechte zu sämtlichen Bildern auf dieser Website liegen, sofern nicht anders gekennzeichnet, bei der 
                        <a href="https://iww.uni-freiburg.de" target="_blank"> Professur für Waldwachtum und Dendroökologie, Albert-Ludwigs-Universität Freiburg</a>
                    </Typography>
                </Paper>
                
            </Box>

            <Footer />
        </Box>
    </>
}

export default DesktopImprint