import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, Button, MobileStepper } from "@mui/material"
import { useSignal } from "@preact/signals-react"
import { posthog } from "../../tracking";

const SLIDES_COUNT = 9

const TutorialSlideshow: React.FC = () => {
    // create a state for the current slide
    const current = useSignal(1)

    // tracking signals
    const tutorialUsed = useSignal(false)
    const tutorialFinished = useSignal(false)

    const handleNext = () => {
        if (current.peek() < SLIDES_COUNT) {
            current.value += 1
        } else {
            current.value = 1
        }

        if (!tutorialUsed.peek()) {
            tutorialUsed.value = true
            posthog.capture('TutorialUsed')
        }

        if (!tutorialFinished.peek() && current.peek() === SLIDES_COUNT - 1) {
            tutorialFinished.value = true
            posthog.capture('TutorialFinished')
        }
    }
    const handleBack = () => {
        if (current.peek() > 1) {
            current.value -= 1
        } else {
            current.value = SLIDES_COUNT
        }
    }
    return <>
    <Box flexGrow={1} display="flex" flexDirection="column" height="90%" width="auto">
        <Box sx={{
            height: 'calc(100% - 60px)', 
            width: 'auto', 
            background: `url(/tutorial/Slide_${current.value}.PNG)`, 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: 'contain',
            backgroundPosition: 'center center'
        }} />
        <MobileStepper
            variant="dots"
            steps={SLIDES_COUNT}
            position="static"
            activeStep={current.value - 1}
            nextButton={<Button variant="contained" onClick={handleNext}>Next <KeyboardArrowRight /></Button>}
            backButton={<Button variant="contained" onClick={handleBack}><KeyboardArrowLeft /> Back</Button>}
            sx={{bgcolor: 'transparent', justifyContent: 'space-between', width: '100%'}}
        />
    </Box>
    </>
}

export default TutorialSlideshow