import { Alert, Box, Button, Grid, IconButton, Modal, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import DraggableTree from "../TreeLines/DraggableTree";
import { Close, Info } from "@mui/icons-material";
import { Signal, useSignal, useSignalEffect } from "@preact/signals-react";
import NutzungsChecker from "./NutzungsChecker";
import { addTreeToPalette, removeTreeFromPalette, treePalette } from "../../appState/appViewSignals";
import DragBox from "../MainActionCard/DragBox";
import { editAge } from "../../appState/treeLocationSignals";
import { useDrop } from "react-dnd";
import { speciesProfiles, TreeProfile } from "../../appState/backendSignals";
import { GiWoodBeam, GiBee, GiBeech, GiCherry } from "react-icons/gi"
import BeeProfileCard from "./BeeProfileCard";
import StandortRange from "./StandortRange";
import { staticData } from "../../appState/summarySignals";
import StandortPopupButton from "../Standort/StandortPopupButton";
import posthog from "posthog-js";

const TreeSpeciesSelectionModal: React.FC<{ isOpen: Signal<boolean> }> = ({ isOpen }) => {
  // use a local signal to set the current selected species
  const currentProfileName = useSignal<string>(treePalette.peek()[0]);
  const currentProfile = useSignal<TreeProfile | undefined>(undefined);

  const isDirty = useSignal<boolean>(false);
  useSignalEffect(() => {
    if (!isDirty.peek() && isOpen.value) {
      isDirty.value = true;
      posthog.capture('TreeSpeciesSelectionModalView')
    } 
  })

  useSignalEffect(() => {
    // whenever the selected name changes, load a new profile
    const profile = speciesProfiles.peek().find(p => p.latin_name === currentProfileName.value);
    currentProfile.value = profile;
  })

  // drop zone for the tree species
  const [, dropPlatte] = useDrop(() => ({
    accept: 'tree',
    drop: (item: any) => {
      // filter out the current tree type, if it is already in the palette
      // const others = treePalette.value.filter(t => t !== item.treeType)

      // // add the item to the end of the palette
      // treePalette.value = [...others, item.treeType]
      addTreeToPalette(item.treeType)
    }
  }))

  const handleRemoveTree = (tree: string) => {
    // treePalette.value = treePalette.peek().filter(t => t !== tree)
    removeTreeFromPalette(tree)
  }

  // console.log(speciesProfile.value);
  return (
    <Modal onClose={() => (isOpen.value = !isOpen.peek())} open={isOpen.value}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "85%",
          maxWidth: "1400px",
          maxHeight: 'calc(100vh - 30px)',
          bgcolor: "background.paper",
          borderRadius: 2,
          p: 4,
          overflowY: 'auto'
        }}
      >
        {/* main title */}
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" mb={1} component="div">Artenauswahl</Typography>
          <Button color="success" variant="contained" onClick={() => isOpen.value = false}>BESTÄTIGEN</Button>
        </Box>

        <Grid container columnSpacing={3} rowSpacing={2}>

          {/* PALETTE */}
          <Grid item xs={6}>
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" component="span">Meine ausgewählten Baumarten</Typography>
              <Tooltip title="Hier kannst du Baum- und Straucharten von unten auswählen und in die Palette ziehen. Danach kannst du sie auf der Karte zum Planen nutzen">
                <Info color="info" /> 
              </Tooltip>
            </Box>

            <Box
              ref={dropPlatte}
              sx={{
                border: "2px dashed",
                borderColor: "grey.400",
                borderRadius: 2,
                bgcolor: "grey.100",
                height: "80px",
                display: "flex",
                alignItems: "center",
                padding: "1rem"
              }}
            >
              { treePalette.value.map((tree, idx) => (
                <Box sx={{position: 'relative'}} key={idx}>
                  <DragBox>
                    <DraggableTree treeType={tree} age={editAge.value} />
                  </DragBox>
                  <IconButton 
                    size="small" 
                    sx={{ position: "absolute", top:  '-16px', right: '-12px'}}
                    onClick={() => handleRemoveTree(tree)}
                  >
                      <Close />
                    </IconButton>
                </Box>
              )) }
              <Typography
                sx={{
                  textAlign: "center",
                  margin: "auto",
                }}
                color="GrayText"
              >
                Hier kannst du deine Baumarten platzieren
              </Typography>
            </Box>

            {/* insert a warning */}
            { treePalette.value.length >= 4 ? (
              <Alert severity="warning">
                Mehr als 4 Baum- und Straucharten können in der Palette nicht gut dargestellt werden. 
                Nutze jetzt 4 Arten und füge wenn du fertig bist weitere Arten hinzu.
              </Alert>
            ) : null }

            <Typography mt={1} variant="h5">Entdecke weitere Baumarten</Typography>
            <Box
              sx={{
                borderRadius: 2,
                //height: "100px",
                display: "flex",
                alignItems: "center",
                flexFlow: "row wrap",
              }}
              component="div"
            >
              {speciesProfiles.peek()?.filter(t => t.type === 'Baum').map((species, idx) => {
                return (
                  <Box mr={2} sx={{maxWidth: '75px', lineHeight: 0.5}} key={idx}>
                    <DragBox 
                      selected={species.latin_name === currentProfileName.value}
                      onClick={() => currentProfileName.value = species.latin_name}
                    >
                      <DraggableTree treeType={species.latin_name} age={50} />
                    </DragBox>
                    <Typography variant="caption">{species.german_name}</Typography>
                  </Box>
                );
              })}
            </Box>


            <Typography mt={1} variant="h5">Entdecke weitere Straucharten</Typography>
            <Box
              sx={{
                borderRadius: 2,
                //height: "100px",
                display: "flex",
                alignItems: "center",
                flexFlow: 'row wrap'
              }}
              component="div"
            >
              {speciesProfiles.peek()?.filter(t => t.type === 'Strauch').map((species, idx) => {
                return (
                  <Box mr={2} sx={{maxWidth: '75px', lineHeight: 0.5}} key={idx}>
                    <DragBox 
                      selected={species.latin_name === currentProfileName.value}
                      onClick={() => currentProfileName.value = species.latin_name}
                    >
                      <DraggableTree treeType={species.latin_name} age={50} />
                    </DragBox>
                    <Typography variant="caption">{species.german_name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          
          {/* DESCRIPTION */}
          <Grid item xs={6} sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h5">{currentProfile.value?.german_name} <i>({currentProfile.value?.latin_name})</i></Typography>
              <Paper elevation={3} sx={{p: 3, mt: 2,  flexGrow: 1, bgcolor: 'grey.100'}}>
                <div dangerouslySetInnerHTML={{
                  __html: (currentProfile.value || {profile: '<i>Keine Daten in der Datenbank!</i>'}).profile
                }} />
              </Paper>
          </Grid>
          
          {/* DETAILS */}
          <Grid item xs={4}>
              <Paper elevation={3} sx={{p: 1, height: '100%', bgcolor: 'grey.100'}}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5">Standortansprüche</Typography>
                  <StandortPopupButton>bearbeiten</StandortPopupButton>
                </Box>
                
                <Box px={2}>
                  <Typography variant="h6">Jährlicher Niederschlag</Typography>
                  <StandortRange 
                    limits={[100, 2000]}
                    range={[currentProfile.value?.precipitation_min || 100, currentProfile.value?.precipitation_max || 2000]}
                    bgcolor="#BDD7EE"
                    suffix="mm"
                    height={20}
                    value={staticData.value.precipitationSum}
                  />
                </Box>

                <Box px={2} mt={1}>
                  <Typography variant="h6">Boden-pH-Wert</Typography>
                  <StandortRange 
                    limits={[1, 14]}
                    range={[currentProfile.value?.ph_min || 1, currentProfile.value?.ph_max || 14]}
                    bgcolor="linear-gradient(90deg, rgba(250,69,69,1) 0%, rgba(220,244,41,1) 15%, rgba(75,255,65,1) 30%, rgba(118,242,232,1) 45%, rgba(89,107,238,1) 60%, rgba(0,212,255,1) 100%);"
                    height={20}
                    value={staticData.value.soilpH}
                  />
                </Box>

                <Box px={2} mt={1}>
                  <Typography variant="h6">Bodenfeuchte</Typography>
                  <StandortRange 
                    limits={[0, 10]}
                    range={[currentProfile.value?.soil_moisture_min || 0, currentProfile.value?.soil_moisture_max || 100]}
                    bgcolor="#D6AD83"
                    // suffix="%"
                    height={20}
                    value={staticData.value.soilMoisture}
                  />
                </Box>

              </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={3} sx={{p: 1, height: '100%', bgcolor: 'grey.100'}}>
              <Typography variant="h5">Nutzungsmöglichkeiten</Typography>
              <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                <NutzungsChecker 
                  label="Wertholzproduktion"
                  icon={<GiBeech color="green" size={40} />}
                  status={currentProfile.value?.wood_production || false}
                />
                <NutzungsChecker 
                  label="Kurzumtrieb/Biomasse"
                  icon={<GiWoodBeam color="green" size={40} />}
                  status={currentProfile.value?.short_rotation || false}
                />
                <NutzungsChecker 
                  label="Streuobst"
                  icon={<GiCherry color="green" size={40} />}
                  status={currentProfile.value?.fruits_nuts || false}
                />
                <NutzungsChecker
                  label="Bienenweide"
                  icon={<GiBee color="green" size={40} />}
                  status={currentProfile.value?.bee_pasture || false}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={3} sx={{p: 1, height: '100%', bgcolor: 'grey.100'}}>
              <Typography variant="h5">Blüten und Bienen</Typography>
              <Box width="100%" flexGrow={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <BeeProfileCard profile={currentProfile.value!} />
              </Box>
            </Paper>
          </Grid>

        </Grid>

      </Box>
    </Modal>
  );
};

export default TreeSpeciesSelectionModal;
