import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Typography } from "@mui/material"
import { Bundesland } from "../../appState/legalSignals"
import { ExpandMore, PictureAsPdf } from "@mui/icons-material"
import { useEffect } from "react"
import posthog from "posthog-js"
import { Document, Page } from "react-pdf"

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;

const LOOKUP: {[key: string]: string} = {
    'Baden-Württemberg': 'BW',
    'Bayern': 'Beispiel',
    'Berlin': 'Beispiel',
    'Brandenburg': 'Beispiel',
    'Bremen': 'Beispiel',
    'Hamburg': 'Beispiel',
    'Hessen': 'Beispiel',
    'Mecklenburg-Vorpommern': 'Beispiel',
    'Niedersachsen': 'Beispiel',
    'Nordrhein-Westfalen': 'Beispiel',
    'Rheinland-Pfalz': 'Beispiel',
    'Saarland': 'Beispiel',
    'Sachsen': 'Beispiel',
    'Sachsen-Anhalt': 'Beispiel',
    'Schleswig-Holstein': 'Beispiel',
    'Thüringen': 'Beispiel',
}


// https://yvpomcfahfgdqzmdzuue.supabase.co/storage/v1/object/public/documents/BW_ordnungsrecht.pdf


const LegalFramework: React.FC = () => {
    // log the use of this tab
    useEffect(() => {
        posthog.capture('LegelTabView', {bundesland: Bundesland.peek()})
    }, [])

    // handle the download 
    const downloadPDF = (type: 'foerderung' | 'ordnungsrecht') => {
        // log this event
        posthog.capture('LegalPDFDownload', {bundesland: Bundesland.peek(), type})

        // build the url
        const bl = Object.keys(LOOKUP).includes(Bundesland.peek()) ? LOOKUP[Bundesland.peek()] : 'BW'
        const filename = `${bl}_${type}.pdf` 
        const url = `${supabaseUrl}/storage/v1/object/public/documents/${filename}`

        // trigger the download
        window.open(url, '_blank')
    }

    return <>
        <Box height="calc(100vh - 105px)" width="100%" maxWidth="1200px" p={3} mx="auto" display="flex" flexDirection="column" sx={{overflowY: 'scroll'}}>
            
            <Typography variant="h4" textAlign="center">Rechtliche Rahmenbedingungen für {Bundesland.value}</Typography>
            
            { Bundesland.value !== 'Baden-Württemberg' ? <>
                <Alert severity="warning" sx={{my: 3}}>
                    Hier werden die rechtlichen Rahmenbedingungen beispielhaft für Baden-Württemberg dargestellt. Die Handreichung für {Bundesland.value} steht erst in der finalen Version des Tools zur Verfügung.
                    Bitte wählen Sie ein anderes Bundesland aus.
                </Alert>
            </> : null }
            <Typography mt={4} variant="h5">Fördermöglichkeiten</Typography>
            <Alert severity="info" sx={{my: 3}}>
                Sie suchen nach einer Fördermöglichkeit für die Anlage oder Pflege Ihres Agroforstsystems? 
                Die folgenden Möglichkeiten bestehen in {Bundesland.value}
            </Alert>
            <Box>
                <Button variant="outlined" size="large" startIcon={<PictureAsPdf />} onClick={() => downloadPDF('foerderung')}>
                    Fördermöglichkeiten in {Bundesland.value} als PDF herunterladen
                </Button>
            </Box>

            <Typography mt={4} variant="h5">Ordnungsrecht</Typography>
            <Alert severity="info" sx={{my: 3}}>
                Sie fragen sich, welche weiteren rechtlichen Vorgaben Sie bei Anlage, Pflege oder Beseitigung 
                Ihres Agroforstsystems beachten müssen? Dies hängt von der Art Ihres Agroforstsystems und dem 
                Einzelfall ab. Um Unklarheiten und Missverständnisse zu vermeiden, wenden Sie sich im Zweifel 
                an die jeweils zuständige Behörde (insbesondere Untere Naturschutzbehörde). Grundsätzlich 
                sind die folgenden Rahmenbedingungen zu beachten
            </Alert>
            <Box>
                <Button variant="outlined" size="large" startIcon={<PictureAsPdf />} onClick={() => downloadPDF('ordnungsrecht')}>
                    Ordnungsrechtliche Rahmenbedingungen in {Bundesland.value} als PDF herunterladen
                </Button>
                {/* <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        Ordnungsrecht
                    </AccordionSummary>
                    <AccordionDetails> */}
                        {/* <Document file="https://yvpomcfahfgdqzmdzuue.supabase.co/storage/v1/object/public/documents/BW_ordnungsrecht.pdf">
                            <Page  pageNumber={1} />
                        </Document> */}
                    {/* </AccordionDetails>
                </Accordion> */}
            </Box>
            
        </Box>
    </>
}

export default LegalFramework